const ADMIN_ID = '286f4d3a-6ffd-484b-a49b-d5023846f218'; 

function resolveImageUrl(url) {
  if (!url) {
    return '';  
  }

  if (url.startsWith('http')) {
    return url;  
  }

  const cleanedUrl = url.startsWith('/') ? url.slice(1) : url;

  return `https://assets.tina.io/${ADMIN_ID}/${cleanedUrl}`;
}

module.exports = { resolveImageUrl };
