import React, { useState, useEffect } from 'react';
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Logo from "../images/flatblack-logo-white.svg";
import menuIcon from '../images/menu-icon.svg';
import closeIcon from '../images/close-icon.svg';

const Nav = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const toggleMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
      setTimeout(() => setMenuVisible(false), 500);
    } else {
      setMenuVisible(true);
      setTimeout(() => setMenuOpen(true), 0);
    }
  };

  const handleScroll = () => {
    if (typeof window !== 'undefined') {
      const currentScrollY = window.scrollY;
      setScrollingUp(currentScrollY < lastScrollY || currentScrollY <= 0);
      setLastScrollY(currentScrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [lastScrollY]);

  // Update the current path when the location changes
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="fixed w-full z-50">
      {/* Mobile Header */}
      <div
        className={`flex justify-between items-center xl:hidden relative transition-all duration-500 ease-in-out ${
          scrollingUp ? 'translate-y-0 bg-black bg-opacity-90' : '-translate-y-full'
        }`}
      >
        <div className="text-left z-50 p-5">
          <Link to="/" itemProp="url">
            <img src={Logo} alt="Logo" className="h-10 inline" />
          </Link>
        </div>
        <div className="w-34 text-right py-0 px-5">
          <button onClick={toggleMenu} className="focus:outline-none relative z-50">
            {menuOpen ? (
              <img src={closeIcon} alt="Close Menu" className="h-12 w-12" />
            ) : (
              <img src={menuIcon} alt="Open Menu" className="h-12 w-12" />
            )}
          </button>
        </div>
      </div>

      {/* Tablet and Mobile Menu */}
      {menuVisible && (
        <div
          className={`fixed top-0 left-0 w-full h-screen bg-black bg-opacity-90 flex flex-col items-center justify-center xl:hidden z-40 transition-transform transition-opacity duration-500 ease-in-out ${
            menuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'
          }`}
        >
          <div className="absolute top-5 right-5 z-50">
            <button onClick={toggleMenu} className="focus:outline-none">
              <img src={closeIcon} alt="Close Menu" className="h-12 w-12" />
            </button>
          </div>
          <div className="grid grid-rows-4 gap-4 font-bold text-4xl text-center mb-10 text-white">
            <Link
              to="/"
              className={`${currentPath === '/' ? 'border-b-2 border-white' : ''}`}
              onClick={toggleMenu}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`${currentPath === '/about/' ? 'border-b-2 border-white' : ''}`}
              onClick={toggleMenu}
            >
              About
            </Link>
            <Link
              to="/project"
              className={`${currentPath === '/project/' ? 'border-b-2 border-white' : ''}`}
              onClick={toggleMenu}
            >
              Projects
            </Link>
            <Link
              to="/contact"
              className={`${currentPath === '/contact/' ? 'border-b-2 border-white' : ''}`}
              onClick={toggleMenu}
            >
              Contact
            </Link>
          </div>
        </div>
      )}

      {/* Desktop Menu */}
      <div className="pt-5 px-10 h-5/6 fixed w-1/6 hidden xl:block">
        <div className="py-5">
          <Link to="/" itemProp="url">
            <img src={Logo} alt="Logo" className="h-16" />
          </Link>
        </div>
        <div className="flex items-center h-full">
          <div className="grid grid-rows-4 grid-flow-col gap-4 text-xl font-semibold">
            <Link
              to="/"
              className={`${currentPath === '/' ? 'w-fit border-b-2 border-white' : ''}`}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`${currentPath === '/about' ? 'w-fit border-b-2 border-white' : ''}`}
            >
              About
            </Link>
            <Link
              to="/project"
              className={`${currentPath === '/project' ? 'w-fit border-b-2 border-white' : ''}`}
            >
              Projects
            </Link>
            <Link
              to="/contact"
              className={`${currentPath === '/contact' ? 'w-fit border-b-2 border-white' : ''}`}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
