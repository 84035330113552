import React from 'react';
const Footer = () => {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-10 gap-4">
        <div className="col-span-2" />
        <div className="col-span-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 border-t border-b border-neutral-50 p-5 md:py-5">
              <div className="flex items-center justify-start"><i className="bx bx-envelope-open text-xl text-white mr-3"></i><span className="">connect@flatblackism.com</span></div>
              <div className="flex items-center justify-start"><i className="bx bx-map-pin text-xl text-white mr-3"></i><span className="">315 N Puente St, Suite D, Brea, CA 92821</span></div>
              <div className="">
                <ul className="">
                  <li className="px-3"><a href="https://instagram.com/flatblack.ism" target="_blank" className="flex gap-3 items-center justify-start"><i className="bx bxl-instagram text-xl text-white inline-block" /><span className="inline-block">@flatblack.ism</span></a></li>
                  {/* <li className="inline px-3"><i className='bx bxl-discord text-xl text-white'></i></li>
                  <li className="inline px-3"><i className='bx bxl-twitter text-xl text-white'></i></li>
                  <li className="inline px-3"><i className='bx bxl-linkedin text-xl text-white'></i></li>
                  <li className="inline px-3"><i className='bx bxl-facebook text-xl text-white'></i></li> */}
                </ul>
              </div>
            </div>
            <div className="col-span-5 w-full p-5 md:py-4 text-sm">© {new Date().getFullYear()} <a href="https://www.flatblackism.com/" title="FLATBLACK">FLATBLACK</a>. All rights reserved.</div>
        </div>
    </div>
  );
};

export default Footer;
