import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectThumbnail from "../components/ProjectThumbnail"
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

const ProjectList = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/project" : `/project/${currentPage - 1}`
  const nextPage = `/project/${currentPage + 1}`
  const nodes = data.allMarkdownRemark.edges

  const renderPagination = () => {
    let items = []
    const maxPagesToShow = 4

    if (currentPage > 1) {
      items.push(<Link key="first" to="/project/" className="relative m-0 inline-flex items-center border border-gray-300 border-opacity-20 px-4 py-2 text-sm font-medium"><ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" /></Link>)
      items.push(<Link key="prev" to={prevPage} className="relative inline-flex items-center border-t border-r border-b border-zinc-300 border-opacity-20 px-4 py-2 text-sm"><ChevronLeftIcon className="h-5 w-5" aria-hidden="true" /></Link>)
    }

    const startPage = Math.max(1, currentPage - 2)
    const endPage = Math.min(numPages, startPage + maxPagesToShow - 1)

    for (let i = startPage; i <= endPage; i++) {
      const linkTo = i === 1 ? "/project/" : `/project/${i}`
      items.push(
        <Link key={i} to={linkTo} className={currentPage === i ? "relative z-10 inline-flex items-center p-5 py-2 text-md focus-visible:bg-zinc-300 border border-white border-opacity-20 -top-[5px]" : "relative inline-flex items-center px-4 py-2 text-sm border-t border-b border-white border-opacity-20 focus:z-20 -top-[5px]"}>{i}</Link>
      )
    }

    if (endPage < numPages) {
      if (endPage < numPages - 1) {
        items.push(<span key="ellipsis" className="relative inline-flex items-center p-5 text-md focus:z-20 -top-[5px]">...</span>)
      }
      items.push(<Link key={numPages} to={`/project/${numPages}`} className="relative inline-flex items-center p-5 text-md border-t border-b border-opacity-20 -top-[5px]">{numPages}</Link>)
    }

    if (currentPage < numPages) {
      items.push(<Link key="next" to={nextPage} className="relative inline-flex items-center border border-white border-opacity-20 px-4 py-2 text-md font-medium "><ChevronRightIcon className="h-5 w-5" aria-hidden="true" /></Link>)
    }

    return <div>{items}</div>
  }

  return (
    <Layout>
      <Seo title="Projects" />
      <div className="container text-zinc-100 text-base overflow-hidden max-w-6xl">
        <div className="">
          <div className="">
            <h1 className="font-bold text-5xl border-b border-white pb-5 mb-10">Projects</h1>
            <p className="my-5">Examples and case studies of our past work</p>
            <div className="grid px-0 py-10 auto-rows-fr grid-cols-2 gap-8 lg:max-w-none lg:grid-cols-3">
              {nodes.map(({ node }) => (
                <ProjectThumbnail key={node.fields.slug} node={node} />
              ))}
            </div>
          </div>
        </div>

        <div className="mt-10 py-5 text-center">
          <div className="inline-block w-full md:w-auto md:flex sm:flex-1 md:items-center md:justify-center">
            <div className="">
              <nav className="" aria-label="Pagination">
                {numPages > 1 && renderPagination()}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProjectList

export const pageQuery = graphql`
  query projectListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/project/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            thumbnail
            previewText
          }
          excerpt
        }
      }
    }
  }
`
